import { EcSite } from '@apis/type/ec_site_pb'
import config, { Environment } from 'config'
import { getPartnerConfig } from '~/models/partner_config'
import { UserRole } from '~/types/user'

type Notification = {
  name: string
}

export type Feature = {
  title: string
  urlPath: string
  description?: string
  notification?: Notification
}

export type FeatureSection = {
  title: string
  items: Feature[]
}

const removeDisabledFeatureSections = (
  sections: Array<FeatureSection | null>,
): FeatureSection[] => {
  return sections.flatMap((e) => (e && e.items.length ? e : []))
}

const getFeatureFlags = (siteType: EcSite.SiteType) => {
  return getPartnerConfig(siteType).featureFlags
}

type FeatureSectionFunction = (siteType: EcSite.SiteType, role: UserRole) => FeatureSection | null

const getOrder: FeatureSectionFunction = (siteType, role) => {
  const featureFlags = getFeatureFlags(siteType)
  return {
    title: '注文',
    items: [
      {
        title: '注文一覧',
        urlPath: '/orders',
        description: 'お客様からの注文の一覧を表示、管理します。',
      },
      ...(featureFlags.enableStailerTMS
        ? [
            {
              title: 'ピッキング伝票',
              urlPath: '/picking_list',
              description: 'ピッキング伝票を印刷できます。',
            },
            {
              title: '配達・お渡し',
              urlPath: '/delivery_cars',
              description:
                '本日付の注文を注文枠別に表示します。お客様にお渡しする明細を印刷できます。',
            },
          ]
        : []),
      ...(featureFlags.enableDeliveryVouchers && isAdmin(role)
        ? [
            {
              title: '配送表・納品書',
              urlPath: '/delivery_vouchers',
              description: '配送指示書、各種配送表、納品書を印刷できます。',
            },
          ]
        : []),

      {
        title: '注文商品検索',
        urlPath: '/orders/products',
        description: '本日付の注文の商品を検索します。商品のステータスを確認できます。',
      },

      ...(!isProduction()
        ? [
            {
              title: 'デバッグ機能（開発環境のみ）',
              urlPath: '/debug',
              description: 'テストのための機能を提供します。',
            },
          ]
        : []),
    ],
  }
}

const getProduct: FeatureSectionFunction = (siteType, role) => {
  const featureFlags = getFeatureFlags(siteType)
  return {
    title: '商品',
    items: [
      {
        title: '商品一覧',
        urlPath: '/products',
        description: '売場に掲載する商品を管理します。',
      },
      ...(isAdminOrManager(role)
        ? [
            {
              title: '商品カテゴリ',
              urlPath: '/ec_site_categories',
              description: '売場に表示される商品カテゴリの階層を確認できます。',
            },
          ]
        : []),
      ...(featureFlags.enableCatalog && isAdmin(role)
        ? [
            {
              title: 'カタログ一覧',
              urlPath: '/catalogs',
              description: 'カタログの管理、追加、編集を行えます。',
            },
          ]
        : []),
      ...(featureFlags.enableRecurringOrder && isAdmin(role)
        ? [
            {
              title: '定期便一覧',
              urlPath: '/recurring_order_settings/users',
              description: '定期便の登録、編集、削除を行えます。',
            },
          ]
        : []),
    ],
  }
}

const getContact: FeatureSectionFunction = (siteType, role) => {
  const featureFlags = getFeatureFlags(siteType)
  return {
    title: 'お客様関連',
    items: [
      ...(!isManager(role)
        ? [
            {
              title: 'お客様一覧',
              urlPath: '/users',
              description: 'お客様の一覧を表示します。',
            },
          ]
        : []),
      ...(featureFlags.enableContants
        ? [
            {
              title: 'お問い合わせ',
              urlPath: '/contacts',
              description: 'お客様からのお問い合わせを表示します。',
            },
          ]
        : []),

      ...(featureFlags.enableSubscriptionV2 && isAdmin(role)
        ? [
            {
              title: '有料サービス',
              urlPath: '/subscription_users',
              description: 'お客様の有料サービスの利用状況を確認、変更できます。',
            },
          ]
        : []),
      ...(featureFlags.enableOutgoingCallHistory && isAdmin(role)
        ? [
            {
              title: '発信履歴',
              urlPath: '/outgoingCallHistory',
              description: 'スタッフからお客様への発信一覧を表示します。',
            },
          ]
        : []),
      ...(featureFlags.enableCompensationClame && isAdmin(role)
        ? [
            {
              title: '補償手続き',
              urlPath: '/compensations',
              description: '売上修正、返金対応などを行えます。',
            },
          ]
        : []),
    ],
  }
}

const getBankAccount: FeatureSectionFunction = (siteType, role) => {
  return getFeatureFlags(siteType).enableBankAccountTransfer && isAdmin(role)
    ? {
        title: '口座振替関連',
        items: [
          {
            title: '口座振替管理',
            urlPath: '/bank_accounts/',
            description: 'お客様への口座振替に関する情報の一覧を表示、管理します。',
          },
          {
            title: '請求データ管理',
            urlPath: '/account_transfer/requests',
            description: 'お客様の口座振替に関する請求データの一覧を表示、管理します。',
          },
          {
            title: '請求書・明細書',
            urlPath: '/account_transfer/statements',
            description: 'お客様の口座振替に関する請求書・明細書を表示、管理します。',
          },
        ],
      }
    : null
}

const getAnnouncement: FeatureSectionFunction = (_, role) => {
  return isAdminOrManager(role)
    ? {
        title: 'お知らせ・プッシュ通知',
        items: [
          {
            title: 'お知らせ',
            urlPath: '/announcements',
            description: 'お客様へのお知らせを管理します。',
          },
          {
            title: 'プッシュ通知',
            urlPath: '/batch_push_notification_reservations',
            description: 'アプリに配信するプッシュ通知を管理します。',
          },
        ],
      }
    : null
}

const getMarketing: FeatureSectionFunction = (siteType, role) => {
  const featureFlags = getFeatureFlags(siteType)
  return {
    title: '販促関連',
    items: [
      ...(isAdminOrManager(role)
        ? [
            {
              title: '掲載バナー',
              urlPath: '/banners',
              description: '売場に掲載するバナーを管理します。',
            },
          ]
        : []),
      ...(featureFlags.showBundleMixmatch
        ? [
            {
              title: 'まとめ割・よりどり割一覧',
              urlPath: '/bundle_rules',
              description: 'まとめ割・よりどり割の商品を管理します。',
            },
          ]
        : []),
      ...(isAdminOrManager(role)
        ? [
            {
              title: 'クーポン一覧',
              urlPath: '/coupons',
              description: 'クーポンを管理します。',
            },
          ]
        : []),
      ...(isAdmin(role)
        ? [
            {
              title: '特集一覧',
              urlPath: '/features',
              description: '特集を管理します。',
            },
          ]
        : []),
      ...(featureFlags.enableReservationProduct && isAdmin(role)
        ? [
            {
              title: '予約特集一覧',
              urlPath: '/reservation_products',
              description: '予約特集を管理します。',
            },
          ]
        : []),
    ],
  }
}

const getSupport: FeatureSectionFunction = (_) => {
  return {
    title: 'サポート',
    items: [
      {
        title: 'ご利用ガイド',
        urlPath: 'https://stailer.zendesk.com/hc/ja',
        description: 'スタッフアプリ・管理画面に関するご利用ガイドです。',
      },
      {
        title: '不具合報告・お問い合わせ',
        urlPath: '/bug-reports',
        description: '管理画面に関する不具合報告・お問い合わせフォームです。',
      },
    ],
  }
}

const getBaseSetting: FeatureSectionFunction = (_, role) => {
  return {
    title: '基本設定',
    items: [
      {
        title: 'アカウント設定',
        urlPath: '/accountSettings',
      },
      ...(isAdmin(role)
        ? [
            {
              title: 'ユーザーアカウント一覧',
              urlPath: '/v2/external_admin_users',
            },
            {
              title: '監査ログ',
              urlPath: '/auditLogs',
            },
          ]
        : []),
    ],
  }
}

const getMasterData: FeatureSectionFunction = (_, role) => {
  return {
    title: 'マスタデータ設定',
    items: [
      ...(isAdmin(role)
        ? [
            {
              title: 'マスタデータ',
              urlPath: '/master_datas',
            },
          ]
        : []),
      ...(isAdminOrManager(role)
        ? [
            {
              title: '商品画像一括更新',
              urlPath: '/upload_product_images',
            },
          ]
        : []),
    ],
  }
}

const getDeliverySetting: FeatureSectionFunction = (_, role) => {
  return {
    title: '注文枠設定',
    items: [
      ...(isAdmin(role)
        ? [
            {
              title: '注文枠ひな形設定',
              urlPath: '/delivery_time_setting',
            },
          ]
        : []),
      ...(isAdminOrManager(role)
        ? [
            {
              title: '注文枠一覧',
              urlPath: '/delivery_times',
            },
          ]
        : []),
    ],
  }
}

const getShop: FeatureSectionFunction = (siteType, role) => {
  return {
    title: '店舗設定',
    items: [
      ...(isAdminOrManager(role)
        ? [
            {
              title: '店舗情報',
              urlPath: '/ec_site_pickup_shops',
            },
          ]
        : []),
      ...[
        {
          title: '配達エリア',
          urlPath: '/delivery_areas',
        },
      ],
      ...(isAdminOrManager(role)
        ? [
            {
              title: '配達エリア移管',
              urlPath: '/delivery_area_migrations',
            },
          ]
        : []),
      ...(isAdmin(role)
        ? [
            ...(siteType !== EcSite.SiteType.FRESTA
              ? [
                  {
                    title: '配達エリアグループ',
                    urlPath: '/delivery_area_groups',
                  },
                ]
              : []),
          ]
        : []),

      ...(isAdmin(role)
        ? [
            {
              title: '店舗グループ設定',
              urlPath: '/shop_groups',
            },
          ]
        : []),
      ...(isAdminOrManager(role)
        ? [
            {
              title: 'ドキュメント一覧',
              urlPath: '/documents',
            },
          ]
        : []),
    ],
  }
}

export const operationFeatureTitle = 'オペレーション設定' as const

// TODO: 名前をoperationから変更する
const getOperationFeatures: FeatureSectionFunction = (_, role) => {
  return {
    title: operationFeatureTitle,
    items: [
      ...(isAdmin(role)
        ? [
            {
              title: '商品配置',
              urlPath: '/fulfillment/product_arrangement',
            },
          ]
        : []),
    ],
  }
}

const getProcurement: FeatureSectionFunction = (siteType, role) => {
  return getFeatureFlags(siteType).enableProcurement && isAdmin(role)
    ? {
        title: '仕入れ関連',
        items: [
          {
            title: '仕入先一覧',
            urlPath: '/suppliers',
            description: '仕入先の管理と新規作成が行なえます。',
          },
          {
            title: '入荷予定',
            urlPath: '/stock_arrivals',
            description: '入荷予定商品の確認が行なえます。',
          },
          {
            title: '仕入れマスタダウンロード',
            urlPath: '/download_procurement_masters',
            description: '販売計画書や需要予測データをダウンロードできます。',
          },
        ],
      }
    : null
}

const getPayment: FeatureSectionFunction = (siteType, role) => {
  const featureFlags = getFeatureFlags(siteType)
  return {
    title: '売上・取引関連',
    items: [
      ...(featureFlags.enableStailerStandardSalesFinalizer
        ? [
            {
              title: '売上確定',
              urlPath: '/stailer_standard_finalize_sale',
              description: '受け取りが完了した注文の売上確定処理を行い、POSに売上を連携します。',
            },
          ]
        : []),

      ...(isAdmin(role)
        ? [
            {
              title: '決済ステータス管理',
              urlPath: '/payment_issues',
              description: '決済が失敗した注文の確認、手動処理を行えます。',
              notification: {
                name: 'payment_issues',
              },
            },
            {
              title: '取引一覧',
              urlPath: '/transaction_records',
              description: '完了した取引の一覧を表示します。',
            },
          ]
        : []),
    ],
  }
}

// ナビゲーションバーのメインメニュー
export const getNavbarMainFeatures = (
  siteType: EcSite.SiteType,
  role: UserRole,
): FeatureSection[] => {
  return removeDisabledFeatureSections([
    getOrder(siteType, role),
    getProduct(siteType, role),
    getContact(siteType, role),
    getPayment(siteType, role),
    getAnnouncement(siteType, role),
    getMarketing(siteType, role),
    getSupport(siteType, role),
  ])
}

// ナビゲーションバーの右端に隠れているメニュー
export const getNavbarOtherFeatures = (
  siteType: EcSite.SiteType,
  role: UserRole,
): FeatureSection[] => {
  return removeDisabledFeatureSections([
    getBaseSetting(siteType, role),
    getMasterData(siteType, role),
    getDeliverySetting(siteType, role),
    getShop(siteType, role),
    getOperationFeatures(siteType, role),
  ])
}

// Dashboardのメインメニュー
export const getMainFeatures = (siteType: EcSite.SiteType, role: UserRole): FeatureSection[] => {
  return removeDisabledFeatureSections([
    getOrder(siteType, role),
    getProduct(siteType, role),
    getContact(siteType, role),
    getPayment(siteType, role),
    getAnnouncement(siteType, role),
    getMarketing(siteType, role),
    getSupport(siteType, role),
    getBankAccount(siteType, role),
    getProcurement(siteType, role),
  ])
}

// Dashboardの設定・その他の左側
export const getLeftSubFeatures = (siteType: EcSite.SiteType, role: UserRole): FeatureSection[] => {
  return removeDisabledFeatureSections([
    getBaseSetting(siteType, role),
    getMasterData(siteType, role),
    getDeliverySetting(siteType, role),
  ])
}

// Dashboardの設定・その他の右側
export const getRightSubFeatures = (
  siteType: EcSite.SiteType,
  role: UserRole,
): FeatureSection[] => {
  return removeDisabledFeatureSections([
    getShop(siteType, role),
    getOperationFeatures(siteType, role),
  ])
}

export const isAdmin = (role: UserRole): boolean => {
  // Developerはすべての機能を確認する想定であるため、Adminと同じページにアクセスできるようにする。
  // フロントエンドは基本的に同等だが、バックエンドでは招待の実行が拒否される点が異なる。
  return role === UserRole.Admin || role === UserRole.Developer
}
export const isManager = (role: UserRole): boolean => {
  return role === UserRole.Manager
}
export const isAdminOrManager = (role: UserRole): boolean => {
  return isAdmin(role) || isManager(role)
}
export const isMember = (role: UserRole): boolean => {
  return role === UserRole.Member
}

const isProduction = (): boolean => {
  return config.environment === Environment.production
}
